/* * {
  outline: 1px solid red;
} */

.App {
  display: flex;
  flex-direction: row;
}

.side-bar {
  flex-grow: 1;
  /* background-color: grey; */
}

.content {
  flex-basis: 60%;
  max-width: 60%;
  /* background-color: aquamarine; */
}

.column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.width-100 {
  width: 100%;
}

.left-pad {
  padding-left: 1rem;
}

.App-header {
  background-color: white;
  font-size: calc(10px + 2vmin);
  color: black;
  top: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 3vh;
  padding-left: 1rem;
}

.logos {
  padding-right: 2rem;
  display: flex;
  flex-direction: row;
}

.App-header > div > h1 {
  margin: 0;
}

.padded {
  padding: 1rem;
}

.query-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.2rem solid #80ed99;
  padding-left: 1rem;
  border-radius: 1rem;
}

.query-item-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 0.2rem solid #80ed99;
  padding-left: 1rem;
  border-radius: 1rem;
}

.single-check {
  padding: 0.1rem;
  display: flex;
  flex-direction: row;
}

.query-checkbox > span {
  margin-right: 1rem;
}

.query-checkbox {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.submit-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2rem;
  margin-top: 1rem;
  background-color: #80ed99;
  border-radius: 2rem;
}

.submit-item:hover {
  background-color: #57cc99;
}

.submit-item > img {
  width: 2rem;
  height: 2rem;
  align-self: center;
}

.macros-q {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #57cc99;
}

h1 > b {
  color: #38a3a5;
}

.right-pad {
  padding-right: 2rem;
  padding-left: 1rem;
}

input[type=text] {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 2rem;
  text-align: right;
}

input[type=button], input[type=submit], input[type=reset] {
  color: black;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  width: 100%;
}

#calorie-input {
  width: 85%;
}

.results {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.result-card {
  border: 0.2rem solid #22577a;
  border-radius: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.result-card-actions {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.result-card:hover {
  background-color: #57cc99;
  cursor: pointer;
}

.result-card-id {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.result-card-id > div {
  width: 50%;
}

ul {
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

li {
  list-style-type: none;
}

.result-card > ul > li {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 1rem;
}

.result-card > ul > li > span {
  margin-right: 0.5rem;
}

.result-card-heading {
  text-align: left;
  margin: 0;
}

.subheading {
  text-align: left;
  margin: 0;
}

.floating-cart {
  position: fixed;
  bottom: 20px; /* Distance from the bottom */
  left: 0;
  right: 0;
  width: 300px; /* Width of the pill */
  height: auto; /* Height of the pill */
  margin: auto; /* Centers the pill horizontally */
  background-color: #80ED99; /* Background color of the pill */
  text-align: center; /* Center the text/icon inside the pill */
  border-radius: 25px; /* Makes it pill-shaped */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: adds a shadow for better visibility */
  z-index: 1000; /* Ensure it's above other content */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}


.floating-cart:hover {
  background-color: #57CC99; /* Darker shade on hover */
}

.floating-cart > div {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.result-card-button {
  margin: 0.3rem;
  background-color: #80ed99;
  border-radius: 50%;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  padding: 0.3rem;
}

.order-item {
  background-color: #57cc99;
}

/*make room for the pill at the bottom*/
.content {
  padding-bottom: 200px;
}

/* Adjustments for mobile */
@media (max-width: 768px) {
  .App {
    flex-direction: column; /* Stack vertically on smaller screens */
  }
  
  .content, .side-bar {
    flex-basis: auto; /* Auto-adjust to content */
    max-width: 100%; /* Use full width on mobile */
  }
}

@media (max-width: 590px) {
  .result-card > ul > li {
    flex-direction: column;
  }
  .result-card > ul {
    justify-content: space-around;
  }
}